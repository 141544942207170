@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 3px white;
  }

  .font-outline-4 {
    -webkit-text-stroke: 4px black;
  }
}

.lighten {
  opacity: 1;
}

#text_1 {
  font-kerning: none;
}



.no-spinner {
  -moz-appearance: textfield;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: Outfit, Urbanist, Arial, Helvetica, sans-serif;
  color: white;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.right-panel-menu ul li {
  height: 44px;
  cursor: pointer;
  padding-top: 15px;
}

.right-panel-menu ul li.active {
  color: #00eefd;
}
.right-panel-menu.transition-transform {
  transition-duration: 600ms;
}

@keyframes slide-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.animated-slide-in {
  animation: slide-in 0.6s forwards;
}

.animated-slide-out {
  animation: slide-out 0.6s forwards;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scroll-container {
  overflow-y: auto;  
  overflow-x: hidden; 
  max-height: 400px;  
  
}

.scroll-container::-webkit-scrollbar {
  width: 3px;  
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}

.scroll-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 12px; /* Match your container's border radius */
}

